import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '@utils/api';

export const complaintsApi = createApi({
  reducerPath: 'complaintsApi',
  baseQuery: baseQueryConfig(),

  endpoints: builder => ({
    getComplaintsList: builder.query({
      query: params => ({
        url: '/complaints/list/complaints/',
        method: 'GET',
        params: {
          ...params,
          filter: params.filter && JSON.stringify(params.filter),
        },
      }),
    }),

    getObjectsList: builder.query<any, any>({
      query: params => ({
        url: '/complaints/list/objects/',
        method: 'GET',
        params: {
          ...params,
          filter: params.filter && JSON.stringify(params.filter),
        },
      }),
    }),

    getModerateObject: builder.query({
      query: params => ({
        url: '/complaints/moderate/',
        method: 'GET',
        params,
      }),
    }),

    getObjectById: builder.query({
      query: objectId => ({
        url: `/complaints/${objectId}/`,
        method: 'GET',
      }),
    }),

    getComplaintsByObjectId: builder.query({
      query: objectId => ({
        url: `/complaints/${objectId}/complaint_list/`,
        method: 'GET',
      }),
    }),

    lockObject: builder.mutation({
      query: objectId => ({
        url: `/complaints/${objectId}/lock/`,
        method: 'POST',
      }),
    }),

    unlockObject: builder.mutation({
      query: objectId => ({
        url: `/complaints/${objectId}/unlock/`,
        method: 'POST',
      }),
    }),

    approveComplaint: builder.mutation({
      query: objectId => ({
        url: `/complaints/${objectId}/approve/`,
        method: 'POST',
      }),
    }),

    rejectComplaint: builder.mutation({
      query: objectId => ({
        url: `/complaints/${objectId}/reject/`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetComplaintsListQuery,
  useGetObjectsListQuery,
  useGetModerateObjectQuery,
  useGetObjectByIdQuery,
  useGetComplaintsByObjectIdQuery,
  useLockObjectMutation,
  useUnlockObjectMutation,
  useApproveComplaintMutation,
  useRejectComplaintMutation,
} = complaintsApi;
