import React, { useCallback, useState } from 'react';
import { Box, BoxProps, OutlinedInput, OutlinedInputProps, styled, TableRow, Typography } from '@mui/material';
import AvatarBadge from '@components/UserCard/AvatarBadge';
import { UserModel, UserProfile } from '@src/types/models';
import { grey } from '@mui/material/colors';
import { black } from '@src/theme/colors';
import { useUpdateUserMutation } from '@api/user';
import CardFormButtonGroup from '@components/CardFormButtonGroup';

type UserInfoTabProps = BoxProps & {
  user: UserModel;
};

type UserProfilePartial = Pick<UserProfile, 'picture' | 'original_picture' | 'nickname' | 'firstname' | 'lastname'>;

type AvatarBadgeProps = {
  editable: boolean;
  profile: UserProfile;
  onRemoveAvatar?: () => void;
  onRestoreAvatar?: () => void;
  cancelChanges?: () => void;
  saveProfile?: () => void;
};

type BaseFieldProps = Omit<OutlinedInputProps, 'name'> & {
  name: keyof UserProfile;
};

const BaseField = styled((props: BaseFieldProps) => <OutlinedInput {...props} autoComplete="off" size="small" fullWidth />)({
  backgroundColor: grey[50],
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: black['A200'],
  },
});

const AvatarContainer = ({
  user,
  editable,
  profile,
  onRemoveAvatar,
  onRestoreAvatar,
  cancelChanges,
  saveProfile,
  ...props
}: UserInfoTabProps & AvatarBadgeProps) => {
  const [formData, setFormData] = useState<UserProfilePartial>({
    picture: profile.picture,
    original_picture: profile.original_picture,
    nickname: profile.nickname,
    firstname: profile.firstname,
    lastname: profile.lastname,
  });

  const [updateUser] = useUpdateUserMutation();

  const updateNickField = async () => {
    await updateUser({ userId: user.id, payload: { profile: formData } }).unwrap();
    saveProfile && saveProfile();
  };

  const onCancelHandler = () => {
    cancelChanges && cancelChanges();
  };

  const ProfileField = useCallback(
    ({ name, ...props }: BaseFieldProps) => {
      return (
        <BaseField
          {...props}
          defaultValue={profile[name] || ''}
          name={name}
          onChange={e =>
            setFormData(
              (prevState: UserProfilePartial): UserProfilePartial => ({
                ...prevState,
                [name]: e.target.value,
              }),
            )
          }
        />
      );
    },
    [profile, setFormData],
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <AvatarBadge
        profile={profile}
        formData={formData}
        editable={editable}
        onRemoveAvatar={() => {
          setFormData((prevState: UserProfilePartial) => ({ ...prevState, picture: null }));
        }}
        onRestoreAvatar={() => {
          setFormData((prevState: UserProfilePartial) => ({
            ...prevState,
            picture: profile.picture,
          }));
        }}
      />
      {!editable ? <Typography>{profile?.nickname}</Typography> : <ProfileField name="nickname" />}
      <TableRow>{editable && <CardFormButtonGroup onCancel={onCancelHandler} onSave={updateNickField} />}</TableRow>
    </Box>
  );
};

export default AvatarContainer;
