import { createApi } from '@reduxjs/toolkit/query/react';
import { Restriction } from '@src/types/models';
import { DEFAULT_PER_PAGE } from '@src/config';
import { baseQueryConfig } from '@utils/api';

export enum RestrictionType {
  FULL_BLOCK = 1, // Полная блокировка
  CONTENT_CREATION = 2, // Создание контента
  USER_SUBSCRIPTION = 3, // Подписка на пользователе
  MONETIZATION = 4, // Включение монетизации
  PROFILE_PHOTO_UPLOAD = 5, // Загрузка фото профиля
  PROFILE_EDIT = 6, // Редактирование данных пользователя
  MENTION_OTHER_USERS = 7, // Упоминание других пользователей
  DONATION_SEND = 8, // Отправка доната
}

export type RestrictionResponse = {
  pagination: {
    total: number;
    page: number;
    page_size: number;
    page_count: number;
  };
  results: Restriction[];
};

export type AddRestrictionRequest = {
  type: RestrictionType;
  end_date?: number | null;
}[];

export interface PostRestrictionResponse {
  status: string;
  message: string;
  accepted_list: number[];
  error: string;
  error_list: string[];
}

export type RemoveRestrictionRequest = [
  {
    type: RestrictionType;
  },
];

export interface ErrorResponse {
  detail: string;
}

export const restrictionApi = createApi({
  reducerPath: 'restrictionApi',
  baseQuery: baseQueryConfig(),

  endpoints: build => ({
    getActiveRestrictions: build.query<RestrictionResponse, { userId: string }>({
      query: ({ userId }) => ({
        url: `/restriction/${userId}/active/`,
      }),
    }),

    getRestrictionHistory: build.query<RestrictionResponse, { userId: string; page?: number; page_size?: number; offset?: number; limit?: number }>({
      query: ({ userId, page = 1, page_size = DEFAULT_PER_PAGE }) => ({
        url: `/restriction/${userId}/history/`,
        params: {
          page,
          page_size,
          // offset,
          // limit,
        },
      }),
    }),

    addRestriction: build.mutation<PostRestrictionResponse | ErrorResponse, { userId: string; body: AddRestrictionRequest }>({
      query: ({ userId, body }) => ({
        url: `/restriction/${userId}/add/`,
        method: 'POST',
        body,
      }),
    }),

    removeRestriction: build.mutation<PostRestrictionResponse | ErrorResponse, { userId: string; body: RemoveRestrictionRequest }>({
      query: ({ userId, body }) => ({
        url: `/restriction/${userId}/remove/`,
        method: 'POST',
        body,
      }),
    }),

    getRestrictionTypes: build.query<any[], void>({
      query: () => ({
        url: 'restriction/types/',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRestrictionHistoryQuery,
  useGetActiveRestrictionsQuery,
  useAddRestrictionMutation,
  useGetRestrictionTypesQuery,
  useRemoveRestrictionMutation,
} = restrictionApi;
