import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '@utils/api';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: baseQueryConfig(),

  endpoints: builder => ({
    getTransferOutList: builder.query({
      query: params => ({
        url: '/payments/transfer_out/list/',
        method: 'GET',
        params,
      }),
    }),
    approveTransferOut: builder.mutation({
      query: toUserId => ({
        url: `/payments/transfer_out/${toUserId}/approve/`,
        method: 'POST',
      }),
    }),
    rejectTransferOut: builder.mutation({
      query: toUserId => ({
        url: `/payments/transfer_out/${toUserId}/reject/`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetTransferOutListQuery, useApproveTransferOutMutation, useRejectTransferOutMutation } = paymentsApi;
