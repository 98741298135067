import React, { createContext, useState, useContext } from 'react';
import { Snackbar, SnackbarContent, IconButton, SnackbarCloseReason } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';

interface SnackbarAction {
  label?: string;
  icon?: React.ReactNode;
  onClick?: (key: string) => void;
}

interface SnackbarMessage {
  id: string;
  message: string;
  severity: 'success' | 'warning' | 'error' | undefined;
  autoHideDuration: number | null;
  manualClose: boolean;
  actions?: SnackbarAction[];
}

const SnackbarContext = createContext<any>(null);

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [snackbars, setSnackbars] = useState<SnackbarMessage[]>([]);

  const showSnackbar = (
    message: string,
    severity: 'success' | 'warning' | 'error' | undefined,
    autoHideDuration: number,
    manualClose: boolean = false,
    actions?: SnackbarAction[],
  ) => {
    const id = uuidv4();
    const newSnackbar = { id, message, severity, autoHideDuration: manualClose ? null : autoHideDuration, manualClose, actions };
    setSnackbars(prev => [newSnackbar, ...prev]);
  };

  const handleCloseSnackbar = (id: string, event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason !== 'clickaway') {
      setSnackbars(prev => prev.filter(snackbar => snackbar.id !== id));
    }
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, handleCloseSnackbar }}>
      {children}
      {snackbars.map((snackbar, index) => {
        return (
          <Snackbar
            key={snackbar.id}
            disableWindowBlurListener={false}
            open={true}
            autoHideDuration={snackbar.autoHideDuration}
            onClose={(event, reason) => handleCloseSnackbar(snackbar.id, event, reason)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{
              position: 'fixed',
              bottom: 20 + index * 65,
              left: '5%',
              zIndex: 1300 + index,
            }}
          >
            <SnackbarContent
              message={snackbar.message}
              action={
                snackbar.actions &&
                snackbar.actions.map((action, idx) => (
                  <IconButton
                    key={idx}
                    aria-label={action.label || 'action'}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      if (action.onClick) {
                        action.onClick(snackbar.id);
                      }
                      handleCloseSnackbar(snackbar.id);
                    }}
                  >
                    {action.icon || <CloseIcon fontSize="inherit" />}
                    {action.label}
                  </IconButton>
                ))
              }
              style={{
                backgroundColor: snackbar.severity === 'error' ? '#f44336' : snackbar.severity === 'warning' ? '#ff9800' : '#4caf50',
                maxWidth: '80vw',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            />
          </Snackbar>
        );
      })}
    </SnackbarContext.Provider>
  );
};
