import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from '@utils/api';
import { BaseContentStatuses, BaseRequestFilters, ExplicitTypes, ListResponseWithPagination, PartialRequestQueryArgs } from '@src/types';
import { UserCommentContentModel } from '@src/types/models';

export type ContentCommentFilterType = BaseRequestFilters & {
  usercontent?: string;
  parent?: string;
  user?: string;
  id__in?: string[];
};

export type ContentCommentRequestType = PartialRequestQueryArgs & {
  order?: string;
  filter?: ContentCommentFilterType;
};

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: baseQueryConfig(),
  tagTypes: ['Comments'],
  endpoints: build => ({
    comments: build.query<ListResponseWithPagination<UserCommentContentModel>, ContentCommentRequestType>({
      query: ({ order = 'created', filter, ...params }) => ({
        url: 'comments/',
        params: {
          ...params,
          order,
          filter: JSON.stringify(filter),
        },
      }),

      providesTags: result =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: 'Comments' as const,
                id,
              })),
              'Comments',
            ]
          : ['Comments'],
    }),

    getCommentDetail: build.query<UserCommentContentModel, string>({
      query: commentId => `comments/${commentId}/`,
      providesTags: (result, error, commentId) => [{ type: 'Comments', id: commentId }],
    }),

    changeCommentStatus: build.mutation<
      UserCommentContentModel,
      {
        status: BaseContentStatuses;
        commentId: string;
      }
    >({
      query: ({ commentId, status }) => ({
        method: 'PATCH',
        url: `comments/${commentId}?comment_id=${commentId}`,
        body: {
          status,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Comments', id: arg.commentId }],
    }),
    changeCommentData: build.mutation<
      any,
      {
        commentId: string;
        status?: number;
        published?: string;
        updated?: string;
        created?: string;
        markers?: number[];
        explicit?: ExplicitTypes;
        moderation?: number;
        retailmention?: number;
        content?: {
          text?: string;
        };
      }
    >({
      query: ({ commentId, content }) => ({
        method: 'PATCH',
        url: `comments/${commentId}?comment_id=${commentId}`,
        body: {
          content,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Comments', id: arg.commentId }],
    }),
  }),
});

export const { useCommentsQuery, useChangeCommentStatusMutation, useChangeCommentDataMutation, useGetCommentDetailQuery } = commentsApi;
