import { useState } from 'react';
import { Button, CircularProgress, Collapse, ListItemButton, ListItemText, Pagination } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  ErrorResponse,
  PostRestrictionResponse,
  RemoveRestrictionRequest,
  useGetRestrictionHistoryQuery,
  useRemoveRestrictionMutation,
} from '@api/restriction';
import { useSnackbar } from '@src/context/SnackbarContext';
import { DEFAULT_PER_PAGE } from '@src/config';

const RestrictionHistoryList = ({ user }: any) => {
  const [open, setOpen] = useState(false);
  const [removeRestriction] = useRemoveRestrictionMutation();
  const [loadingStates, setLoadingStates] = useState<{ [key: number]: boolean }>({});
  const [page, setPage] = useState(1);

  const {
    data: historyRestrictions,
    isLoading,
    refetch,
    isFetching,
  } = useGetRestrictionHistoryQuery({ userId: user?.id, page, page_size: DEFAULT_PER_PAGE }, { refetchOnMountOrArgChange: true });

  const { showSnackbar } = useSnackbar();

  const handleRemoveRestriction = async (restrictionType: number) => {
    const body: RemoveRestrictionRequest = [
      {
        type: restrictionType,
      },
    ];
    setLoadingStates(prev => ({ ...prev, [restrictionType]: true }));
    try {
      const response: ErrorResponse | PostRestrictionResponse = await removeRestriction({ userId: user?.id, body }).unwrap();
      if ('status' in response) {
        if (response.status === 'ok') {
          showSnackbar(response.message, 'success', 2000, false);
          refetch();
        } else {
          showSnackbar(response.error || 'Ошибка при снятии ограничения.', 'error', 2000, false);
        }
      } else {
        showSnackbar('Неизвестная ошибка.', 'error', 2000, false);
      }
    } catch (error: any) {
      showSnackbar(error?.data?.message || 'Ошибка при снятии ограничения.', 'error', 2000, false);
    } finally {
      setLoadingStates(prev => ({ ...prev, [restrictionType]: false }));
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    refetch();
  };

  const handleClick = () => {
    setOpen(!open);
  };

  /* TODO - время приходит UTC - 2024-12-10T11:59:19Z пока так*/

  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ pl: 1 }}>
        <ListItemText primary={!isLoading ? `История ограничений (${historyRestrictions?.pagination.total})` : 'loading...'} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {isLoading || isFetching ? (
          <CircularProgress size={40} sx={{ display: 'flex', margin: '20px auto' }} color="primary" />
        ) : (
          historyRestrictions?.results.map((restriction: any, index: any) => {
            const textColor = restriction.is_active ? 'black' : 'gray';
            return (
              <ListItemButton key={index} sx={{ pl: 4 }}>
                <ListItemText
                  sx={{ color: textColor }}
                  primary={
                    <>
                      {`${new Date(restriction.start_date).toLocaleString()} - `}
                      {restriction.end_date ? new Date(restriction.end_date).toLocaleString() : <strong>Навсегда</strong>}
                      {`, Статус ограничения: ${restriction.is_active ? 'Активно' : 'Истекло'}`}
                    </>
                  }
                  secondary={`${restriction.title}`}
                />
                {restriction.is_active && (
                  <Button onClick={() => handleRemoveRestriction(restriction?.type)} disabled={loadingStates[restriction.type]}>
                    {loadingStates[restriction.type] ? 'Снимаем...' : 'Снять ограничение'}
                  </Button>
                )}
              </ListItemButton>
            );
          })
        )}

        <Pagination
          count={historyRestrictions?.pagination?.total && Math.ceil(historyRestrictions?.pagination?.total / DEFAULT_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color="primary"
          sx={{ margin: 2, display: 'flex', justifyContent: 'center' }}
        />
      </Collapse>
    </>
  );
};

export default RestrictionHistoryList;
