import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

export type InlineButtonProps = ButtonProps & {};

const InlineButton = styled(({ disableRipple = true, ...props }: InlineButtonProps) => <Button {...props} disableRipple={disableRipple} />)(({ theme }) => ({
  textTransform: 'none',
  lineHeight: 'normal',
  textAlign: 'left',
  padding: 0,
  minWidth: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
}));

export default InlineButton;
