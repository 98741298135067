import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '@utils/api';

export const transferYoutubeApi = createApi({
  reducerPath: 'transferYoutubeApi',
  baseQuery: baseQueryConfig(),

  endpoints: builder => ({
    downloadVideo: builder.mutation<
      any,
      {
        youtube_url: string;
        user_id: string;
      }
    >({
      query: ({ youtube_url, user_id }) => ({
        method: 'POST',
        url: 'download',
        body: {
          youtube_url,
          user_id,
        },
      }),
    }),
  }),
});

export const { useDownloadVideoMutation } = transferYoutubeApi;
